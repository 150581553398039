import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { capitalize } from 'lodash'
import {
  CacheUser,
  OptionType,
  PurchaseRequest,
  ResponseType,
  TableColumnType,
  AccountMyOfferBookEditDetailsFormType
} from '@types'
import { purchaseRequest as graphqlPurchaseRequest, user as graphqlUser } from '@graphql'
import { Select } from '@components'
import { Input, Button } from '@elements'
import { AccountDetailsLayout, AccountLayout } from '@layouts'
import { Form } from '@modules'
import { useGraphqlPurchaseRequest, useWindowSize } from '@hooks'
import { addDays } from '@utils'
import './../my-offer-book.scss'

import ArrowIcon from '@images/back-arrow.svg'
import { css } from '@emotion/css'
import cls from 'classnames'

export interface AccountMyOfferBookEditDetailsProps {
  purchaseRequestId: string
}

const EXPIRATION_DAYS = [
  { label: '5 Days', value: '5' },
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' },
  { label: 'Good ‘Til Cancelled ', value: 'null' }
]

const getExpirationDays = (expiration: string, updatedAt: string): OptionType => {
  const MILLISECONDS_TO_DAYS_DIVIDER = 1000 * 60 * 60 * 24

  const expirationDate = new Date(expiration)
  const updatedAtDate = new Date(updatedAt)
  const dateDifference = +expirationDate - +updatedAtDate
  const dateDifferenceInDays = Math.round(dateDifference / MILLISECONDS_TO_DAYS_DIVIDER)
  const res = EXPIRATION_DAYS.find(option => {
    return +option.value === dateDifferenceInDays
  })

  return res || EXPIRATION_DAYS[0]
}
const AccountMyOfferBookEditDetailsForm = Form<AccountMyOfferBookEditDetailsFormType>()

const AccountMyOfferBookEditDetails: React.FC<AccountMyOfferBookEditDetailsProps> = props => {
  const { purchaseRequestId } = props
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { updatePurchaseRequest } = useGraphqlPurchaseRequest()
  const { data: userData, loading: userLoading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const { data: purchaseRequestData, loading: purchaseRequestLoading } = useQuery<ResponseType<PurchaseRequest>>(
    graphqlPurchaseRequest.FetchPurchaseRequest,
    {
      variables: { id: purchaseRequestId }
    }
  )

  const [newPrice, setNewPrice] = useState<number | undefined>()
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)

  const user = userData && userData.res
  const purchaseRequest = purchaseRequestData && purchaseRequestData.res
  const isClosedRequest = purchaseRequest?.status === 'CLOSED'

  if (isClosedRequest) {
    navigate('/account/my-offer-book')
    return null
  }

  if (!userLoading && !user) navigate('/home/')

  useEffect(() => {
    if (expiresAt && updatedAt) {
      const defaultExpirationDays = getExpirationDays(expiresAt, updatedAt)
      setSelectedExpirationDays(defaultExpirationDays.value)
    }
  }, [purchaseRequest])

  const backUrl = '/account/my-offer-book'

  if (!purchaseRequestLoading && !purchaseRequest) navigate(backUrl)
  if (!purchaseRequest) return null

  const { golfClubType, itemPrice, updatedAt, expiresAt, golfEquipmentType } = purchaseRequest

  const avatar = golfClubType?.golfClubModel?.avatar || golfEquipmentType?.golfEquipmentModel?.avatar || ''
  const brand = golfClubType?.golfClubModel?.brand.title || golfEquipmentType?.golfEquipmentModel?.brand.title || ''
  const title = golfClubType?.golfClubModel?.title || golfEquipmentType?.golfEquipmentModel?.title || ''
  const category =
    golfClubType?.golfClubModel?.category.title || golfEquipmentType?.golfEquipmentModel?.category.title || ''
  const releaseYears =
    golfClubType?.golfClubModel?.releaseYear || golfEquipmentType?.golfEquipmentModel?.releaseYear || ''
  const option = golfClubType?.option || golfEquipmentType?.option || ''
  const slug = golfClubType?.golfClubModel?.slug || golfEquipmentType?.golfEquipmentModel?.slug

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'golfClubType.golfClubModel.title',
      render() {
        return <>{title}</>
      }
    },
    {
      title: 'Category',
      key: 'category',
      render() {
        return <>{category}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render() {
        return <>{brand}</>
      }
    },
    {
      title: 'Release Year',
      key: 'golfClubType.golfClubModel.releaseYear',
      render() {
        return <>{releaseYears}</>
      }
    },
    {
      title: 'Flex',
      key: 'golfClubType.flex',
      render(flex: string) {
        return <>{capitalize(flex)}</>
      }
    },
    {
      title: 'Dexterity',
      key: 'golfClubType.dexterity',
      render(dexterity: string) {
        return <>{capitalize(dexterity)}</>
      }
    },
    {
      title: 'Condition',
      key: 'golfClubType.condition'
    },
    {
      title: 'Option',
      key: 'golfClubType.option',
      render() {
        return <>{option}</>
      }
    }
  ]

  const handlePurchaseRequest = async () => {
    const expirationDaysNumber = +selectedExpirationDays
    const expiresAt = expirationDaysNumber
      ? addDays(new Date(), expirationDaysNumber).toISOString().split('T')[0]
      : null
    const price = newPrice || purchaseRequest?.itemPrice
    const id = golfClubType?.id || golfEquipmentType?.id
    const isGolfClub = purchaseRequest?.golfClubType !== null

    const getPayload = () => {
      if (!isGolfClub)
        return {
          id: purchaseRequestId,
          purchaseRequest: {
            addressCardId: user?.defaultAddressCard?.id,
            expiresAt,
            golfEquipmentTypeId: id,
            paymentCardId: user?.defaultPaymentCard?.id,
            price
          }
        }

      return {
        id: purchaseRequestId,
        purchaseRequest: {
          addressCardId: user?.defaultAddressCard?.id,
          expiresAt,
          golfClubTypeId: id,
          paymentCardId: user?.defaultPaymentCard?.id,
          price
        }
      }
    }

    if (purchaseRequestId && id) {
      await updatePurchaseRequest(getPayload()).then(async res => {
        if (res?.successful) await navigate(`/account/my-offer-book/${purchaseRequestId}/details`)
      })
    }
  }

  return (
    <AccountLayout pageClassName={'my-offer-book-edit-details'} hideLayout={isMobileScreen}>
      {isMobileScreen ? (
        <AccountMyOfferBookEditDetailsForm onSubmit={handlePurchaseRequest} onError={() => null}>
          {useFormMethods => {
            const { getValues } = useFormMethods
            const setPrice = getValues().price
            return (
              <div className={'account-my-offer-book-details-mobile'}>
                <div
                  className={'account-my-offer-book-details-mobile-back-button'}
                  onClick={() => navigate('/account/my-offer-book')}
                >
                  <ArrowIcon />
                  My Offer Book
                </div>
                <div className={'account-my-offer-book-details-mobile-heading'}>
                  <img src={avatar || ''} alt={'avatar'} />
                  <div className={'account-my-offer-book-details-mobile-heading-info'}>
                    <Link to={`/product/${slug}`}>
                      <strong>{golfClubType?.golfClubModel?.title}</strong>
                    </Link>
                    <div className={'account-my-offer-book-details-mobile-heading-info-brand'}>{brand}</div>
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-select-item__first'}>
                  <label>
                    <em className={'uppercase'}>Offer Price</em>
                    <div className={'account-my-offer-book-details-footer-select-item__first-hint'}>
                      * You must meet the minimum offer of $15
                    </div>
                  </label>
                  <Input
                    defaultValue={itemPrice.toString()}
                    name={'price'}
                    placeholder="$0.00"
                    type="number"
                    onChange={val => setNewPrice(+val)}
                    validation={{
                      valueAsNumber: true,
                      required: { value: true, message: 'Offer is required' },
                      min: { value: 15, message: 'Minimum offer is $15' }
                    }}
                  />
                </div>
                <div className={'account-my-offer-book-details-footer-select-item'}>
                  <div className={'account-my-offer-book-details-footer-select-item__second'}>
                    <label>
                      <em>EXPIRATION</em>
                    </label>
                    <Select
                      className={css`
                        width: 400px; !important;
                      `}
                      name={'expiration'}
                      clearable={false}
                      options={EXPIRATION_DAYS}
                      value={selectedExpirationDays}
                      onChange={val => val && setSelectedExpirationDays(val)}
                      rounded
                    />
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-button-group'}>
                  <Button
                    type={'roundBlack'}
                    onClick={() => navigate(`/account/my-offer-book/${purchaseRequestId}/details`)}
                  >
                    Cancel
                  </Button>
                  <Button disabled={setPrice < 15} type={'roundWithHoverBg'} onClick={handlePurchaseRequest}>
                    Save
                  </Button>
                </div>
              </div>
            )
          }}
        </AccountMyOfferBookEditDetailsForm>
      ) : (
        <AccountMyOfferBookEditDetailsForm onSubmit={handlePurchaseRequest} onError={() => null}>
          {useFormMethods => {
            const { getValues } = useFormMethods

            const setPrice = getValues().price
            return (
              <AccountDetailsLayout
                title={'My Offer Book'}
                details={{
                  title: 'Item',
                  text: `${title} ${brand}`
                }}
                backUrl={backUrl}
                avatar={avatar}
                columns={columns}
                data={purchaseRequest}
                loading={userLoading || purchaseRequestLoading}
              >
                <div className={'account-my-offer-book-details-footer-hint'}>
                  * You must meet the minimum offer of $15
                </div>
                <div className={'account-my-offer-book-details-footer-select'}>
                  <div className={'account-my-offer-book-details-footer-select-item'}>
                    <div className={'account-my-offer-book-details-footer-select-item__first'}>
                      <label>
                        <em className={'uppercase'}>value</em>
                      </label>
                      <Input
                        defaultValue={itemPrice.toString()}
                        name={'price'}
                        placeholder="$0.00"
                        type="number"
                        onChange={val => setNewPrice(+val)}
                        validation={{
                          valueAsNumber: true,
                          required: { value: true, message: 'Offer is required' },
                          min: { value: 15, message: 'Minimum offer is $15' }
                        }}
                      />
                    </div>
                  </div>
                  <div className={'account-my-offer-book-details-footer-select-item'}>
                    <div className={'account-my-offer-book-details-footer-select-item__second'}>
                      <label>
                        <em>EXPIRATION</em>
                      </label>
                      <Select
                        classNameDropdown={css`
                          position: relative;
                        `}
                        name={'expiration'}
                        clearable={false}
                        options={EXPIRATION_DAYS}
                        value={selectedExpirationDays}
                        onChange={val => val && setSelectedExpirationDays(val)}
                        rounded
                      />
                    </div>
                  </div>
                </div>
                <div className={'account-my-offer-book-details-footer-buttons'}>
                  <Button
                    type={'roundBlack'}
                    size={'xs'}
                    onClick={() => navigate(`/account/my-offer-book/${purchaseRequestId}/details`)}
                  >
                    Cancel
                  </Button>
                  <Button disabled={setPrice < 15} type={'roundWithHoverBg'} submit>
                    Save
                  </Button>
                </div>
              </AccountDetailsLayout>
            )
          }}
        </AccountMyOfferBookEditDetailsForm>
      )}
    </AccountLayout>
  )
}

export default AccountMyOfferBookEditDetails
