import React from 'react'
import loadable from '@loadable/component'
import { Fallback } from '@components'

export const AccountLayout = loadable(() => import('./AccountLayout'), {
  fallback: <Fallback height={'100vh'} />
})

export const AccountDetailsLayout = loadable(() => import('./AccountDetailsLayout'), {
  fallback: <Fallback height={'100vh'} />
})

export const CheckoutLayout = loadable(() => import('./CheckoutLayout'), {
  fallback: <Fallback height={'100vh'} />
})

export const MobileAccountLayout = loadable(() => import('./MobileAccountLayout'), {
  fallback: <Fallback height={'100vh'} />
})

export const MobileAccountDetailsLayout = loadable(() => import('./MobileAccountDetailsLayout'), {
  fallback: <Fallback height={'100vh'} />
})
